import { AccountingAccountsResource } from "../features/accounting-accounts/accounting-accounts.resource";
import { AntiFallPpeTypesResource } from "../features/anti-fall-ppe-types/anti-fall-ppe-types.resource";
import { AssetsResource } from "../features/assets/assets.resource";
import { ClientsResource } from "../features/clients/clients.resource";
import { DocumentationIssueTypesResource } from "../features/documentation-issue-types/documentation-issue-types.resource";
import { DocumentationTypesResource } from "../features/documentation-types/documentation-types.resource";
import { ExpenseTypesResource } from "../features/expense-types/expense-types.resource";
import { InterventionsResource } from "../features/interventions/interventions.resource";
import { PaymentMethodsResource } from "../features/payment-methods/payment-methods.resource";
import { OperativeUnitsResource } from "../features/operative-units/operative-units.resource";
import { StorageService } from "../common/storage/storage.service";
import { AuthResource } from "../common/security/auth.resource";
import { AuthStoreService } from "../common/security/auth-store.service";
import { GlobalErrorHandler } from "../common/global-error-handlers/global-error-handler";
import { ApiHttpClient } from "../common/http/api-http-client";
import { VueApp } from "../common/vue-app/vue-app";
import { Router } from "../common/router/router";
import { UsersResource } from "../features/users/users.resource";
import { WorkplacesResource } from "../features/workplaces/workplaces.resource";
import { WorkplaceTypesResource } from "../features/workplace-types/workplace-types.resource";
import { ManufacturersResource } from "../features/manufacturers/manufacturers.resource";
import { ModelsResource } from "../features/models/models.resource";
import { TaskTypesResource } from "../features/task-types/task-types.resource";
import { VehiclesResource } from "../features/vehicles/vehicles.resource";
import { CompaniesResource } from "../features/companies/companies.resource";
import { SupplierResource } from "../features/suppliers/supplier.resource";
import { TimeOffTypesResource } from "../features/time-off-types/time-off-types.resource";
import { DossiersResource } from "../features/dossiers/dossiers.resource";
import { DateFormatterService } from "../common/date-formatter/date-formatter.service";
import { ContactsResource } from "../features/contacts/contacts.resource";
import { DietTypesResource } from "../features/diet-types/diet-types.resource";
import { OffersResource } from "../features/offer/offers.resource";
import { OrdersResource } from "../features/order/orders.resource";
import { ProjectsResource } from "../features/projects/projects.resource";
import { ProjectsHaveDietTypesResource } from "../features/projects-have-diet-types/projects-have-diet-types.resource";
import { ExpensesResource } from "../features/expense/expenses.resource";
import { ElementsResource } from "../features/element/elements.resource";
import { ReferenceDocumentsResource } from "../features/reference-document/reference-documents.resource";
import { WorksheetsResource } from "../features/worksheets/worksheets.resource";
import { TasksResource } from "../features/tasks/tasks.resource";
import { SecurityManager } from "../common/security/security.manager";
import { CacheService } from "../common/storage/cache.service";
import { TaskFilesResource } from "@/core/features/task-files/task-files.resource";
import { WarehousesResource } from "@/core/features/warehouses/warehouses.resource";
import { MetricsResource } from "@/core/features/metrics/metrics.resource";
import { ConsumablesResource } from "@/core/features/consumables/consumables.resource";
import { WarehousesConsumableResource } from "@/core/features/warehouse-consumable/warehouses-consumable.resource";
import { StockRecordsResource } from "@/core/features/stock-records/stock-records.resource";
import { TimesOffResource } from "@/core/features/times-off/times-off.resource";
import { ProjectScheduleUsersResource } from "@/core/features/project-schedule-users/project-schedule-users.resource";
import { DocumentationIssuesResource } from "@/core/features/documentation-issues/documentation-issues.resource";
import { ZonesResource } from "@/core/features/zones/zones.resource";
import { WorksheetsHaveVehiclesResource } from "@/core/features/worksheets-have-vehicles/worksheets-have-vehicles.resource";
import { WorksheetsHaveUsersResource } from "@/core/features/worksheets-have-users/worksheets-have-users.resource";
import { ProjectsHaveUsersResource } from "@/core/features/projects-have-users/projects-have-users.resource";
import { ProjectsHaveVehiclesResource } from "@/core/features/projects-have-vehicles/projects-have-vehicles.resource";
import { CertificationsResource } from "@/core/features/certifications/certifications.resource";
import { ActivityUserReportsResource } from "@/core/features/reports/activity-user/activity-user-reports.resource";
import { ReportTimeGroupByResource } from "@/core/features/reports/report-time-group-by.resource";
import { CommentsResource } from "@/core/features/comments/comments.resource";
import { ProjectsHaveCertificationsResource } from "@/core/features/projects-have-certifications/projects-have-certifications.resource";
import { ProjectsHaveContactsResource } from "@/core/features/projects-have-contacts/projects-have-contacts.resource";
import { ProjectsHaveTaskTypesResource } from "@/core/features/projects-have-task-types/projects-have-task-types.resource";
import { RolesResource } from "@/core/features/roles/roles.resource";
import { InvoicesResource } from "@/core/features/invoice/invoices.resource";
import { ProjectEconomicAnalysesResource } from "@/core/features/project-economic-analyses/project-economic-analyses.resource";
import { NumberFormatterService } from "@/core/common/number-formatter/number-formatter.service";
import { SubtasksResource } from "@/core/features/subtasks/subtasks.resource";
import { ViewsResource } from "@/core/features/views/views.resource";
import { BusinessLinesResource } from "@/core/features/business-line/business-lines.resource";
import { ProjectPhasesResource } from "@/core/features/project-phases/project-phases.resource";
import { DossiersPresenter } from "@/modules/dossiers/dossiers.presenter";
import { ProjectsPresenter } from "@/modules/projects/projects.presenter";
import { WorksheetsPresenter } from "@/modules/worksheets/worksheets.presenter";
import { TimesOffHaveUsersResource } from "@/core/features/times-off-have-users/times-off-have-users.resource";
import { TimesOffHaveFilesResource } from "@/core/features/times-off-have-files/times-off-have-files.resource";
import { PublicViewsResource } from "@/core/features/public-views/public-views.resource";
import { ProjectsHaveFilesResource } from "@/core/features/projects-have-files/projects-have-files.resource";
import { TaskFilesPresenter } from "@/modules/worksheets/tables/task-files/task-files.presenter";
import { ProjectsHaveOrdersResource } from "@/core/features/projects-have-orders/projects-have-orders.resource";
import { ErrorNotificationsResource } from "@/core/features/error-notifications/error-notifications.resource";
import { ClientGroupsResource } from "@/core/features/client-groups/client-groups.resource";
import { CalendarsPresenter } from "@/modules/calendar/calendars.presenter";
import { UsersHaveVacationsResource } from "@/core/features/users-have-vacations/users-have-vacations.resource";
import { AdjustVacationsDaysResource } from "@/core/features/adjust-vacations-days/adjust-vacations-days.resource";
import { CollectiveAgreementsResource } from "@/core/features/collective-agreements/collective-agreements.resource";
import { CollectiveAgreementsHaveDietTypesResource } from "@/core/features/collective-agreements-have-diet-types/collective-agreements-have-diet-types.resource";
import { WorksheetDocumentsResource } from "@/core/features/worksheet-documents/worksheet-documents.resource";
import { WorksheetDocumentsPresenter } from "@/modules/worksheets/tables/worksheet-documents/worksheet-documents.presenter";
import { ClientTypesResource } from "@/core/features/client-types/client-types.resource";
import { PostsResource } from "@/core/features/posts/posts.resource";
import { UsersHaveQualificationsResource } from "@/core/features/users-have-qualifications/users-have-qualifications.resource";
import { QualificationsResource } from "@/core/features/qualifications/qualifications.resource";
import { ElevatorsResource } from "@/core/features/elevators/elevators.resource";
import { LocationsResource } from "@/core/features/locations/locations.resource";
import { CategoriesResource } from "@/core/features/categories/categories.resource";
import { SubcategoriesResource } from "@/core/features/subcategories/subcategories.resource";
import { BrandsResource } from "@/core/features/brands/brands.resource";
import { ItemModelsResource } from "@/core/features/item-models/item-models.resource";
import { ItemsResource } from "@/core/features/items/items.resource";
import { CertificationEconomicAnalysesResource } from "@/core/features/certification-economic-analyses/certification-economic-analyses.resource";
import { AppInfoResource } from "@/core/features/app-info/app-info.resource";
import { VehiclesHaveDocumentsResource } from "@/core/features/vehicles-have-documents/vehicles-have-documents.resource";
import { VehicleMaintenancesResource } from "@/core/features/vehicle-maintenances/vehicle-maintenances.resource";
import { MileagesResource } from "@/core/features/mileages/mileages.resource";
import { VehicleTypesHaveChecklistsResource } from "@/core/features/vehicle-types-have-checklists/vehicle-types-have-checklists.resource";
import { ChecklistsResource } from "@/core/features/checklists/checklists.resource";
import { QuestionsResource } from "@/core/features/questions/questions.resource";
import { AnswerOptionsResource } from "@/core/features/answer-option/answer-options.resource";
import { OnCallsResource } from "@/core/features/on-calls/on-calls.resource";
import { OnCallHaveUsersResource } from "@/core/features/on-call-have-users/on-call-have-users.resource";
import { OnCallTypesResource } from "@/core/features/on-call-types/on-call-types.resource";
import { OperativeUnitsHaveOnCallTypesResource } from "@/core/features/operative-units-have-on-calls/operative-units-have-on-call-types.resource";
import { VehicleIssuesResource } from "@/core/features/vehicle-issues/vehicle-issues.resource";
import { AnswersResource } from "@/core/features/answers/answers.resource";
import { VehiclesHaveChecklistsResource } from "@/core/features/vehicles-have-checklists/vehicles-have-checklists.resource";

// Services
export const dateFormatterService = new DateFormatterService();
export const numberFormatterService = new NumberFormatterService();
export const storageService = new StorageService();
export const cacheService = new CacheService();
export const authStoreService = new AuthStoreService(process.env.VUE_APP_AUTH_TOKEN_KEY, storageService);
export const apiHttpClient = new ApiHttpClient(process.env.VUE_APP_SERVER_URL, authStoreService);

// Resources
export const appInfoResource = new AppInfoResource(apiHttpClient);
export const accountingAccountsResource = new AccountingAccountsResource(apiHttpClient);
export const activityUserReportsResource = new ActivityUserReportsResource(apiHttpClient);
export const adjustVacationsDaysResource = new AdjustVacationsDaysResource(apiHttpClient);
export const answersResource = new AnswersResource(apiHttpClient);
export const answerOptionsResource = new AnswerOptionsResource(apiHttpClient);
export const antiFallPpeTypesResource = new AntiFallPpeTypesResource(apiHttpClient);
export const assetsResource = new AssetsResource(apiHttpClient);
export const authResource = new AuthResource(apiHttpClient);
export const brandsResource = new BrandsResource(apiHttpClient);
export const businessLinesResource = new BusinessLinesResource(apiHttpClient);
export const categoriesResource = new CategoriesResource(apiHttpClient);
export const certificationsResource = new CertificationsResource(apiHttpClient);
export const certificationEconomicAnalysesResource = new CertificationEconomicAnalysesResource(apiHttpClient);
export const checklistsResource = new ChecklistsResource(apiHttpClient);
export const clientGroupsResource = new ClientGroupsResource(apiHttpClient);
export const clientTypesResource = new ClientTypesResource(apiHttpClient);
export const clientsResource = new ClientsResource(apiHttpClient);
export const consumablesResource = new ConsumablesResource(apiHttpClient);
export const contactsResource = new ContactsResource(apiHttpClient);
export const collectiveAgreementsResource = new CollectiveAgreementsResource(apiHttpClient);
export const collectiveAgreementsHaveDietTypesResource = new CollectiveAgreementsHaveDietTypesResource(apiHttpClient);
export const commentsResource = new CommentsResource(apiHttpClient);
export const companiesResource = new CompaniesResource(apiHttpClient);
export const dietTypesResource = new DietTypesResource(apiHttpClient);
export const documentationIssuesResource = new DocumentationIssuesResource(apiHttpClient);
export const documentationIssueTypesResource = new DocumentationIssueTypesResource(apiHttpClient);
export const documentationTypesResource = new DocumentationTypesResource(apiHttpClient);
export const elementsResource = new ElementsResource(apiHttpClient);
export const elevatorsResource = new ElevatorsResource(apiHttpClient);
export const expensesResource = new ExpensesResource(apiHttpClient);
export const expenseTypesResource = new ExpenseTypesResource(apiHttpClient);
export const interventionsResource = new InterventionsResource(apiHttpClient);
export const invoicesResource = new InvoicesResource(apiHttpClient);
export const itemModelsResource = new ItemModelsResource(apiHttpClient);
export const itemsResource = new ItemsResource(apiHttpClient);
export const manufacturersResource = new ManufacturersResource(apiHttpClient);
export const metricsResource = new MetricsResource(apiHttpClient);
export const modelsResource = new ModelsResource(apiHttpClient);
export const offersResource = new OffersResource(apiHttpClient);
export const onCallHaveUsersResource = new OnCallHaveUsersResource(apiHttpClient);
export const onCallTypesResource = new OnCallTypesResource(apiHttpClient);
export const onCallsResource = new OnCallsResource(apiHttpClient);
export const ordersResource = new OrdersResource(apiHttpClient);
export const operativeUnitsResource = new OperativeUnitsResource(apiHttpClient);
export const operativeUnitsHaveOnCallTypesResource = new OperativeUnitsHaveOnCallTypesResource(apiHttpClient);
export const paymentMethodsResource = new PaymentMethodsResource(apiHttpClient);
export const projectPhasesResource = new ProjectPhasesResource(apiHttpClient);
export const publicViewsResource = new PublicViewsResource(apiHttpClient);
export const dossiersResource = new DossiersResource(apiHttpClient);
export const referenceDocumentsResource = new ReferenceDocumentsResource(apiHttpClient);
export const reportTimeGroupByResource = new ReportTimeGroupByResource(apiHttpClient);
export const rolesResource = new RolesResource(apiHttpClient);
export const locationsResource = new LocationsResource(apiHttpClient);
export const mileagesResource = new MileagesResource(apiHttpClient);
export const stockRecordsResource = new StockRecordsResource(apiHttpClient);
export const subcategoriesResource = new SubcategoriesResource(apiHttpClient);
export const subtasksResource = new SubtasksResource(apiHttpClient);
export const supplierResource = new SupplierResource(apiHttpClient);
export const taskFilesResource = new TaskFilesResource(apiHttpClient);
export const tasksResource = new TasksResource(apiHttpClient);
export const taskTypesResource = new TaskTypesResource(apiHttpClient);
export const timesOffResource = new TimesOffResource(apiHttpClient);
export const timesOffHaveUsersResource = new TimesOffHaveUsersResource(apiHttpClient);
export const timesOffHaveFilesResource = new TimesOffHaveFilesResource(apiHttpClient);
export const timeOffTypesResource = new TimeOffTypesResource(apiHttpClient);
export const qualificationsResource = new QualificationsResource(apiHttpClient);
export const questionsResource = new QuestionsResource(apiHttpClient);
export const usersResource = new UsersResource(apiHttpClient, cacheService);
export const usersHaveQualificationsResource = new UsersHaveQualificationsResource(apiHttpClient);
export const usersHaveVacationsResource = new UsersHaveVacationsResource(apiHttpClient);
export const vehicleIssuesResource = new VehicleIssuesResource(apiHttpClient);
export const vehiclesResource = new VehiclesResource(apiHttpClient);
export const vehicleMaintenancesResource = new VehicleMaintenancesResource(apiHttpClient);
export const vehiclesHaveChecklistsResource: VehiclesHaveChecklistsResource = new VehiclesHaveChecklistsResource(
  apiHttpClient
);
export const vehiclesHaveDocumentsResource = new VehiclesHaveDocumentsResource(apiHttpClient);
export const viewsResource = new ViewsResource(apiHttpClient);
export const warehousesConsumableResource = new WarehousesConsumableResource(apiHttpClient);
export const warehousesResource = new WarehousesResource(apiHttpClient);
export const postsResource = new PostsResource(apiHttpClient);
export const projectEconomicAnalysesResource = new ProjectEconomicAnalysesResource(apiHttpClient);
export const projectsHaveCertificationsResource = new ProjectsHaveCertificationsResource(apiHttpClient);
export const projectsHaveContactsResource = new ProjectsHaveContactsResource(apiHttpClient);
export const projectsHaveDietTypesResource = new ProjectsHaveDietTypesResource(apiHttpClient);
export const projectsHaveTaskTypesResource = new ProjectsHaveTaskTypesResource(apiHttpClient);
export const projectsHaveUsersResource = new ProjectsHaveUsersResource(apiHttpClient);
export const projectsHaveOrdersResource = new ProjectsHaveOrdersResource(apiHttpClient);
export const projectsHaveVehiclesResource = new ProjectsHaveVehiclesResource(apiHttpClient);
export const projectsHaveFilesResource = new ProjectsHaveFilesResource(apiHttpClient);
export const projectsResource = new ProjectsResource(apiHttpClient);
export const projectScheduleUsersResource = new ProjectScheduleUsersResource(apiHttpClient);
export const vehicleTypesHaveChecklistsResource = new VehicleTypesHaveChecklistsResource(apiHttpClient);
export const worksheetsHaveUsersResource = new WorksheetsHaveUsersResource(apiHttpClient);
export const worksheetsHaveVehiclesResource = new WorksheetsHaveVehiclesResource(apiHttpClient);
export const worksheetsResource = new WorksheetsResource(apiHttpClient);
export const workplacesResource = new WorkplacesResource(apiHttpClient);
export const worksheetDocumentsResource = new WorksheetDocumentsResource(apiHttpClient);
export const workplaceTypesResource = new WorkplaceTypesResource(apiHttpClient);
export const zonesResource = new ZonesResource(apiHttpClient);

export const errorNotificationsResource = new ErrorNotificationsResource(apiHttpClient);

// Managers
export const securityManager = new SecurityManager(authStoreService, authResource, cacheService);

// Presenters
export const dossiersPresenter = new DossiersPresenter(dossiersResource);
export const projectsPresenter = new ProjectsPresenter(projectsResource);
export const worksheetsPresenter = new WorksheetsPresenter(worksheetsResource);
export const taskFilesPresenter = new TaskFilesPresenter(taskFilesResource);
export const worksheetDocumentsPresenter = new WorksheetDocumentsPresenter(worksheetDocumentsResource);
export const calendarsPresenter = new CalendarsPresenter();

// Vue related
export const router = new Router(authStoreService, securityManager, usersResource, postsResource);
export const vueApp = new VueApp(process.env.VUE_APP_FILESTACK_API_KEY);

// Global error handling
export const globalErrorHandler = new GlobalErrorHandler(router, authStoreService);
