import { RouteRecordRaw } from "vue-router";
import { PermissionEnum } from "@altertec_gparn/lib";

export default {
  path: "/projects",
  name: "Projects View",
  meta: { sectionAccess: "PROJECTS", redirects: true },
  component: () => import("@/layouts/EmptyLayout.vue"),
  children: [
    {
      path: "dossier",
      name: "Dossiers",
      redirect: { name: "Dossier List" },
      meta: { permissions: [PermissionEnum.PROJECTS_DOSSIER] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "list",
          name: "Dossier List",
          component: () => import("@/views/auth/projects/dossiers/Dossiers.vue"),
        },
        {
          path: "new",
          name: "Create Dossier",
          component: () => import("@/views/auth/projects/dossiers/CreateDossier.vue"),
        },
        {
          path: "detail/:id",
          name: "Dossier Detail",
          redirect: { name: "Dossier Economic Summary" },
          component: () => import("@/views/auth/projects/dossiers/DossierDetail.vue"),
          props: true,
          children: [
            {
              path: "summary",
              name: "Dossier Economic Summary",
              component: () => import("@/modules/dossiers/tabs/EconomicSummaryTab.vue"),
              props: true,
            },
            {
              path: "projects",
              name: "Dossier's Projects",
              component: () => import("@/modules/dossiers/tabs/ProjectsTab.vue"),
              props: true,
            },
            {
              path: "certifications",
              name: "Dossier's Certifications",
              component: () => import("@/modules/dossiers/tabs/CertificationsTab.vue"),
              props: true,
            },
            {
              path: "worksheets",
              name: "Dossier's Worksheets",
              component: () => import("@/modules/dossiers/tabs/WorksheetsTab.vue"),
              props: true,
            },
            {
              path: "expenses",
              name: "Dossier's Expenses",
              component: () => import("@/modules/dossiers/tabs/ExpensesTab.vue"),
              props: true,
            },
            {
              path: "on-calls",
              name: "Dossier's On Calls",
              component: () => import("@/modules/dossiers/tabs/DossierOnCallsTab.vue"),
              props: true,
            },
          ],
        },
        {
          path: "edit/:id",
          name: "Edit Dossier",
          component: () => import("@/views/auth/projects/dossiers/EditDossier.vue"),
          props: true,
        },
      ],
    },
    {
      path: "worksheets",
      name: "Worksheets",
      meta: { permissions: [PermissionEnum.PROJECTS_WORKSHEET] },
      redirect: { name: "Worksheet List" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "list",
          name: "Worksheet List",
          component: () => import("@/views/auth/projects/worksheets/WorksheetList.vue"),
          props: true,
        },
        {
          path: "detail/:id?",
          name: "Worksheet Detail",
          redirect: { name: "Worksheet Sidebar" },
          components: {
            default: () => import("@/views/auth/projects/worksheets/WorksheetDetail.vue"),
          },
          props: true,
          children: [
            {
              path: "",
              name: "Worksheet Sidebar",
              components: {
                sidebar: () => import("@/modules/worksheets/view-parts/worksheet-detail/WorksheetRightSidebar.vue"),
              },
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "calendar/:id?/:paramDate?",
      name: "Calendar Report",
      meta: { permissions: [PermissionEnum.PROJECT_CALENDAR] },
      component: () => import("@/views/auth/hr/CalendarReport.vue"),
      props: true,
    },
    {
      path: "projects",
      name: "Projects",
      redirect: { name: "Projects List" },
      meta: { permissions: [PermissionEnum.PROJECTS_PROJECT] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "list",
          name: "Projects List",
          component: () => import("@/views/auth/projects/projects/ProjectList.vue"),
        },
        {
          path: "edit/:id",
          name: "Edit Project",
          component: () => import("@/views/auth/projects/projects/EditProject.vue"),
          props: true,
        },
        {
          path: "new",
          name: "New Project",
          component: () => import("@/views/auth/projects/projects/NewProject.vue"),
        },
        {
          path: "copy/:id",
          name: "Copy Project",
          component: () => import("@/views/auth/projects/projects/CopyProject.vue"),
          props: true,
        },
        {
          path: "detail/:id",
          name: "Project Detail",
          redirect: { name: "Project Resources" },
          component: () => import("@/views/auth/projects/projects/ProjectDetail.vue"),
          props: true,
          children: [
            {
              path: "contacts",
              name: "Project Contacts",
              components: {
                default: () => import("@/modules/projects/components/tabs/ProjectContactsTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/ProjectRightSidebar.vue"),
              },
              props: true,
            },
            {
              path: "expenses",
              name: "Project Expenses",
              components: {
                default: () => import("@/modules/projects/components/tabs/ProjectExpensesTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/ProjectRightSidebar.vue"),
              },
              props: true,
            },
            {
              path: "on-calls",
              name: "Project On Calls",
              components: {
                default: () => import("@/modules/projects/components/tabs/ProjectOnCallsTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/ProjectOnCallsSidebar.vue"),
              },
              props: true,
            },
            {
              path: "worksheets",
              name: "Project Worksheets",
              components: {
                default: () => import("@/modules/projects/components/tabs/ProjectWorksheetsTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/ProjectRightSidebar.vue"),
              },
              props: true,
            },
            {
              path: "economic",
              name: "Project Economic",
              components: {
                default: () => import("@/modules/projects/components/tabs/ProjectEconomicTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/ProjectRightSidebar.vue"),
              },
              props: true,
            },
            {
              path: "documentation",
              name: "Project Documentation",
              components: {
                default: () => import("@/modules/projects/components/tabs/ProjectDocumentationTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/ProjectRightSidebar.vue"),
              },
              props: true,
            },
            {
              path: "worksheet-documentation",
              name: "Project Worksheet Documentation",
              components: {
                default: () => import("@/modules/projects/components/tabs/ProjectWorksheetDocumentationTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/ProjectRightSidebar.vue"),
              },
              props: true,
            },
            {
              path: "task-files",
              name: "Project Task Files",
              components: {
                default: () => import("@/modules/projects/components/tabs/ProjectTaskFilesTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/ProjectRightSidebar.vue"),
              },
              props: true,
            },
            {
              path: "configuration",
              name: "Project Configuration",
              components: {
                default: () => import("@/modules/projects/components/tabs/ProjectConfigurationTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/ProjectRightSidebar.vue"),
              },
              props: true,
            },
            {
              path: "resources",
              name: "Project Resources",
              components: {
                default: () => import("@/modules/projects/components/tabs/ProjectResourcesTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/ProjectRightSidebar.vue"),
              },
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "expenses",
      name: "Expenses Layout",
      redirect: { name: "Expenses" },
      meta: { permissions: [PermissionEnum.PROJECTS_EXPENSES] },
      component: () => import("@/views/auth/projects/Expenses.vue"),
      children: [
        {
          path: ":id?",
          name: "Expenses",
          components: {
            default: () => import("@/views/auth/projects/Expenses.vue"),
            sidebar: () => import("@/modules/expenses/components/sidebars/ExpenseSidebar.vue"),
          },
          props: true,
        },
      ],
    },
    {
      path: "files",
      name: "Files",
      meta: { permissions: [PermissionEnum.PROJECTS_FILES] },
      redirect: { name: "Reference documents" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "reference-documents/:type?/:id?",
          name: "Reference documents",
          component: () => import("@/views/auth/projects/ReferenceFiles.vue"),
          props: true,
        },
        {
          path: "worksheet-document",
          name: "Worksheet document",
          component: () => import("@/views/auth/projects/WorksheetDocuments.vue"),
          props: true,
        },
      ],
    },
    {
      path: "task-files",
      name: "Task Files",
      meta: { permissions: [PermissionEnum.PROJECTS_TASK_FILES] },
      component: () => import("@/views/auth/projects/TaskFiles.vue"),
    },
    {
      path: "on-calls",
      name: "On Calls",
      meta: { permissions: [PermissionEnum.PROJECTS_ON_CALL] },
      redirect: { name: "On Calls List" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "new",
          name: "Create On Call",
          component: () => import("@/views/auth/projects/on-call/OnCallCreate.vue"),
        },
        {
          path: "list",
          name: "On Calls List",
          component: () => import("@/views/auth/projects/on-call/OnCallList.vue"),
        },
        {
          path: "detail/:id",
          name: "On Call Detail",
          redirect: { name: "On Call Sidebar" },
          component: () => import("@/views/auth/projects/on-call/OnCallDetailLayout.vue"),
          props: true,
          children: [
            {
              path: "",
              name: "On Call Sidebar",
              components: { sidebar: () => import("@/modules/on-calls/components/sidebars/OnCallSidebar.vue") },
              props: true,
            },
          ],
        },
      ],
    },
    {
      name: "Economic analysis",
      path: "economic-analysis",
      meta: { permissions: [PermissionEnum.PROJECTS_ECONOMIC_ANALYSIS] },
      redirect: { name: "Economic analysis - Project List" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          name: "Economic analysis - Project List",
          path: "list",
          component: () => import("@/views/auth/projects/EconomicAnalysisProjectList.vue"),
        },
        {
          name: "Economic analysis - Project Detail",
          path: "detail/:id",
          component: () =>
            import("@/modules/projects/view-parts/economic-analysis-detail/EconomicAnalysisProjectDetail.vue"),
          props: true,
        },
      ],
    },
    {
      path: "certifications",
      name: "Certifications",
      meta: { permissions: [PermissionEnum.CERTIFICATIONS_PROJECTS] },
      redirect: { name: "Certification List" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "new/:id",
          name: "New Certification",
          props: true,
          component: () => import("@/views/auth/projects/certifications/CreateCertification.vue"),
        },
        {
          path: "list",
          name: "Certification List",
          component: () => import("@/views/auth/projects/certifications/CertificationList.vue"),
        },
        {
          path: "detail/:id",
          name: "Certification Detail",
          redirect: { name: "Certification Detail's summary" },
          component: () => import("@/views/auth/projects/certifications/CertificationDetail.vue"),
          props: true,
          children: [
            {
              path: "summary",
              name: "Certification Detail's summary",
              component: () => import("@/modules/certifications/components/tabs/CertificationSummaryTab.vue"),
              props: true,
            },
            {
              path: "worksheets",
              name: "Certification Detail's Worksheets",
              component: () => import("@/modules/certifications/components/tabs/CertificationWorksheetsTab.vue"),
              props: true,
            },
            {
              path: "on-calls",
              name: "Certification Detail's on calls",
              component: () => import("@/modules/certifications/components/tabs/CertificationOnCallsTab.vue"),
              props: true,
            },
            {
              path: "projects",
              name: "Certification Detail's Projects",
              component: () => import("@/modules/certifications/components/tabs/CertificationProjectsTab.vue"),
              props: true,
            },
            {
              path: "expenses/:expenseId?",
              name: "Certifications' Detail's expenses",
              components: {
                default: () => import("@/modules/certifications/components/tabs/CertificationExpensesTab.vue"),
                sidebar: () =>
                  import(
                    "@/modules/certifications/view-parts/certification-detail/CertificationDetailRightSidebar.vue"
                  ),
              },
              props: true,
            },
            {
              path: "invoice/:selectedId?",
              name: "Certifications' Detail's invoice",
              components: {
                default: () => import("@/modules/certifications/components/tabs/CertificationInvoiceTab.vue"),
                sidebar: () =>
                  import(
                    "@/modules/certifications/view-parts/certification-detail/CertificationDetailRightSidebar.vue"
                  ),
              },
              props: true,
            },
            {
              path: "economic-analysis",
              name: "Certifications' Detail's Economic Analysis",
              component: () =>
                import(
                  "@/modules/certifications/view-parts/certification-detail/CertificationDetailEconomicAnalysis.vue"
                ),
              props: true,
            },
          ],
        },
      ],
    },
  ],
} as RouteRecordRaw;
