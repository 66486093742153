import { Expose } from "class-transformer";

export class CreateAnswerDto {
  @Expose()
  questionId: string;

  @Expose()
  equipmentId?: string;

  @Expose()
  documentationId?: string;

  @Expose()
  selectedAnswerId?: string;

  @Expose()
  freeAnswer?: string;

  @Expose()
  vehicleHasChecklistId: string;
}
